import React from "react";
import "./Footer.css";
import Logo from "../../Data/Images/artic-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const [t] = useTranslation("common");
  const navigate = useNavigate();

  function handleNavigate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    navigate("/privacy");
  }

  return (
    <footer className="font">
      <div className="blackFooter">
        <div className="BF1 rightBorder">
          <h3>{t("footer1")}</h3>
          <p>
            {t("footer2")}
          </p>
          <p>
            {t("footer2added")}
          </p>
          <p>
            {t("footer2added2")}
          </p>
        </div>
        <div className="BF2">
          <h3>{t("footer3")}</h3>
          <img src={Logo} alt="" className="footerLogo" loading="lazy"/>
          <p>
            <FontAwesomeIcon icon={faLocationDot} className="icon" />
            Cesta Dr. Franje Tuđmana 1081, 21216, Kaštel Štafilić
          </p>
          <a href="tel:+385976695570" className="blue">
            <FontAwesomeIcon icon={faPhone} className="icon" />
            +385 97 669 5570
          </a>
          <a href="mailto:info.articrent@gmail.com" className="blue">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            info.articrent@gmail.com
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=+385976695570"
            target="_blank"
            className="blue"
            rel="noreferrer"
          >
            <i className="fab fa-whatsapp icon"></i>
            WhatsApp
          </a>
          <p>
            <FontAwesomeIcon icon={faClock} className="icon" />
            07:00-00:00
          </p>
        </div>
      </div>
      <div className="blueFooter privacy">
        <p className="blueFooterP">Artic Trade © 2024</p>
        <a href="privacy" onClick={() => handleNavigate()} className="blueFooterP2">{t("privacy")}</a>
      </div>
    </footer>
  );
}
