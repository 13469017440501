import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import About from "../../Components/About/About";
import Common from "../../Components/CommonInfo/Common";
import CarsComponent from "../../Components/Cars/CarsComponent";
import Map from "../../Components/Map/Map";
import { Helmet } from "react-helmet-async";

export default function Main() {
  return (
    <div>
      <Helmet>
        <title>Artic Rent a Car Split</title>
        <meta
          name="Artic Rent a Car Split"
          content="Landing page for Artic Rent a Car Split"
        />
      </Helmet>
      <Header />
      <Common />
      <CarsComponent />
      <About />
      <Map />
      <Footer />
    </div>
  );
}
