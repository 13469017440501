import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Privacy.css";
import { useTranslation } from "react-i18next";

export default function Privacy() {
  const [t] = useTranslation("common");

  return (
    <div>
      <Header />
      <div className="privacyMain">
        <div className="font privacyDiv">
          <h1>{t("privacy")}</h1>
          <p>{t("privacy2")}</p>
          <h2>{t("privacy3")}</h2>
          <p>{t("privacy4")}</p>
          <h2>{t("privacy5")}</h2>
          <p>{t("privacy6")}</p>
          <h2>{t("privacy7")}</h2>
          <p>{t("privacy8")}</p>
          <h2>{t("privacy9")}</h2>
          <p>{t("privacy10")}</p>
          <h2>{t("privacy11")}</h2>
          <p>{t("privacy12")}</p>
          <h2>{t("privacy13")}</h2>
          <p>{t("privacy14")}</p>
          <h2>{t("privacy15")}</h2>
          <p>{t("privacy16")}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
