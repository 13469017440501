import "./Header.css";
import Logo from "../../Data/Images/artic-logo.jpg";
import CRO from "../../Data/Images/hr.png";
import ENG from "../../Data/Images/uk.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { slide as Menu } from "react-burger-menu";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 40;
      setIsScrolled(scrollTop > scrollThreshold);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    setIsMenuOpen(false);
  }

  function handleScrollToAbout() {
    navigate("/")
    setTimeout(() => {
      const about = document.getElementById("about");
      if (about) {
        about.scrollIntoView({ behavior: "smooth" });
        setIsMenuOpen(false);
      }
    }, 200);
    
  }

  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    navigate("/");
    setIsMenuOpen(false)
  }

  function openHamburgerMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <header className="font">
      <div id="topbar" className="topbar">
        <>
          <p className="mr-20">
            <FontAwesomeIcon icon={faLocationDot} className="icon" />
            Cesta Dr. Franje Tuđmana 1081, 21216, Kaštel Štafilić
          </p>
        </>
        <a href="tel:+385976695570" className="blue">
          <FontAwesomeIcon icon={faPhone} />
          +385 97 669 5570
        </a>
        <a href="mailto:info.articrent@gmail.com" className="blue">
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          info.articrent@gmail.com
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=+385976695570"
          target="_blank"
          className="blue"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp icon"></i>
          WhatsApp
        </a>
      </div>
      <div className={`header ${isScrolled ? "scrolled" : ""}`}>
        <img
          loading="lazy"
          src={Logo}
          alt="Logo"
          className="mr-20 logo"
          onClick={() => handleScrollToTop()}
        />
        <p onClick={() => handleScrollToTop()} className="home-text wide">
          {t("home")}
        </p>
        <p onClick={() => handleScrollToAbout()} className="header-text wide">
          {t("whyUs")}
        </p>
        <p onClick={() => handleScrollToBottom()} className="header-text wide">
          {t("info")}
        </p>
        <LanguageSelector prop={false} />
        {isMenuOpen ? (
          <Menu className="menu" isOpen={isMenuOpen} right disableOverlayClick>
            <FontAwesomeIcon
              onClick={() => openHamburgerMenu()}
              icon={faXmark}
              className="menu-icon"
              size="3x"
            />
            <p className="menu-item" onClick={() => handleScrollToTop()}>
              {t("home")}
            </p>
            <p className="menu-item" onClick={() => handleScrollToAbout()}>
              {t("whyUs")}
            </p>
            <p
              className="menu-item"
              onClick={() => {
                openHamburgerMenu();
                handleScrollToBottom();
              }}
            >
              {t("info")}
            </p>
            <LanguageSelector prop={true} />
          </Menu>
        ) : (
          <FontAwesomeIcon
            onClick={() => openHamburgerMenu()}
            icon={faBars}
            className="icon header-text hamburger"
            size="3x"
          />
        )}
      </div>
    </header>
  );
}

function LanguageSelector({ prop }) {
  const { i18n } = useTranslation();
  const [ukVis, setUkVis] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("lang") === "hr") {
      setUkVis(false);
    }
  }, []);

  const changeLanguage = (selectedLanguage) => {
    if (selectedLanguage === "hr") {
      setUkVis(false);
    } else {
      setUkVis(true);
    }
    localStorage.setItem("lang", selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div className={`header-text font center wide${prop}`}>
      {ukVis ? (
        <img src={ENG} className="flag" alt="UK Flag" loading="lazy"/>
      ) : (
        <img src={CRO} className="flag" alt="CRO Flag" loading="lazy"/>
      )}
      <select
        className="select font"
        value={i18n.language}
        onChange={(e) => changeLanguage(e.target.value)}
      >
        <option value="en">English</option>
        <option value="hr">Hrvatski</option>
      </select>
    </div>
  );
}
