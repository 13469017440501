import React from "react";

export default function Map() {
  return (
    <div>
        <iframe
          title="Map"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Artic%20rent%20a%20car%20split%20airport+(Artic%20rent%20a%20car)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps devices</a>
        </iframe>
    </div>
  );
}
