import React from "react";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleLine, faTag, faCarBurst, faClipboardUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function About() {
  const [t] = useTranslation("common");

  return (
    <div id="about" className="font centerTxt">
      <h1>{t("whyUs")}</h1>
      <div className="aboutMainDiv">
        <div className="aboutGridItem">
          <div className="iconHeader">
            <FontAwesomeIcon icon={faPeopleLine} className="icon" size="2x" />
            <h3>{t("about1")}</h3>
          </div>
          <div className="iconHeader">{t("about2")}</div>
        </div>
        <div className="aboutGridItem">
          {" "}
          <div className="iconHeader">
            <FontAwesomeIcon icon={faTag} className="icon" size="2x" />
            <h3>{t("about3")}</h3>
          </div>
          <div className="iconHeader">{t("about4")}</div>
        </div>
        <div className="aboutGridItem">
          {" "}
          <div className="iconHeader">
            <FontAwesomeIcon icon={faClipboardUser} className="icon" size="2x" />
            <h3>{t("about5")}</h3>
          </div>
          <div className="iconHeader">{t("about6")}</div>
        </div>
        <div className="aboutGridItem">
          {" "}
          <div className="iconHeader">
            <FontAwesomeIcon icon={faCarBurst} className="icon" size="2x" />
            <h3>{t("about7")}</h3>
          </div>
          <div className="iconHeader">{t("about8")}</div>
        </div>
      </div>
    </div>
  );
}
