import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import './Error.css';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Error() {
  const [t] = useTranslation("common");
  const navigate = useNavigate();

  return (
    <div>
      <Header/>
      <div className='fill font'>
        <h1>{t("error")}</h1>
        <button className='backBtn' onClick={() => navigate("/")}>{t("back")}</button>
      </div>
      <Footer/>
    </div>
  )
}
