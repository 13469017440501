import React from "react";
import bmw from "../../Data/Images/bmw.jpg";
import "./Common.css";
import { useTranslation } from "react-i18next";

const ContainerWithBackground = () => {
  const [t] = useTranslation("common");

  function handleScrollToAbout() {
    const about = document.getElementById("about");
    if (about) {
      about.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div
      id="background"
      className="container font"
      style={{ backgroundImage: `url(${bmw})` }}
    >
      <div className="centered-div">
        <h1 className="h1text">{t("commonh1")}</h1>
        <button onClick={() => handleScrollToAbout()} className='backBtn'>{t("whyUs")}</button>
      </div>
    </div>
  );
};

export default ContainerWithBackground;
